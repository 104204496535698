import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';

const ExportToExcel = ({ tableRef, fileName = "table_data.xlsx" }) => {
    
    const handleExport = () => {
        if (!tableRef.current) {
            console.error("Table reference is not provided.");
            return;
        }
        
        const tableElement = tableRef.current;
        const worksheet = XLSX.utils.table_to_sheet(tableElement);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(data, fileName);
    };

    return (
        <Button variant="success" onClick={handleExport}>
            Export to Excel
        </Button>
    );
};

export default ExportToExcel;
