import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import '../PlaceOrders/placedorder.css';
import ExportToExcel from '../ExportToExcel/ExportToExcel';


const UserCatalogue = () => {
    const [usersData, setUsersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingRow, setEditingRow] = useState(null);
    const [editedData, setEditedData] = useState({});
    const tableRef = useRef();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_users_data`);
                if (res.status === 200) {
                    setUsersData(res.data.results);
                    setFilteredData(res.data.results);
                }
            } catch (error) {
                console.error("Error fetching user data:", error.message);
            }
        };
        fetchData();
    }, []);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        if (query === '') {
            setFilteredData(usersData);
        } else {
            setFilteredData(usersData.filter(user => user.firstName.toLowerCase().includes(query)));
        }
    };

    const handleDelete = async (userId) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/remove_user/${userId}`);
            if (res.status === 200) {
                alert("Deleted Successfully");
                const updatedUsers = usersData.filter(user => user.id !== userId);
                setUsersData(updatedUsers);
                setFilteredData(updatedUsers);
            }
        } catch (error) {
            alert("Error deleting user. Please try again later.");
        }
    };

    const handleEdit = (index, user) => {
        setEditingRow(index);
        setEditedData({ ...user });
    };

    const handleCancel=()=>{
        setEditingRow('')
    }

    const handleChange = (e, field) => {
        setEditedData({ ...editedData, [field]: e.target.value });
    };

    const handleSave = async (userId) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_users_data/${userId}`, editedData);
            if (res.status === 200) {
                alert("Updated Successfully");
                const updatedUsers = usersData.map(user => (user.id === userId ? editedData : user));
                setUsersData(updatedUsers);
                setFilteredData(updatedUsers);
                setEditingRow(null);
            }
        } catch (error) {
            alert("Error updating user data. Please try again later.");
        }
    };

    return (
        <div className="placed_order_container">
            <div className="category_head" style={{ marginTop: '0' }}>
                <h3>Users Catalogue</h3>
            </div>

            <div className="search-container mx-4 mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by First Name..."
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)' }}
                />
            </div>

            <div className="table-container">
                <div className="inventory_table">
                     <div className='text-center mb-2'>
                                  <ExportToExcel tableRef={tableRef} fileName="Users_Catelogue.xlsx" />
                                  </div>
                    <div className="table">
                                    
                        
                        <table ref={tableRef} className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr.no</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>PinCode</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.length > 0 ? (
                                    filteredData.map((user, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.firstName || ''} onChange={(e) => handleChange(e, 'firstName')} /> : user.firstName}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.lastName || ''} onChange={(e) => handleChange(e, 'lastName')} /> : user.lastName}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.email || ''} onChange={(e) => handleChange(e, 'email')} /> : user.email}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.phoneNumber || ''} onChange={(e) => handleChange(e, 'phoneNumber')} /> : user.phoneNumber}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.address1 || ''} onChange={(e) => handleChange(e, 'address1')} /> : user.address1}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.city || ''} onChange={(e) => handleChange(e, 'city')} /> : user.city}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.state || ''} onChange={(e) => handleChange(e, 'state')} /> : user.state}</td>
                                            <td>{editingRow === index ? <input type="text" value={editedData.pincode || ''} onChange={(e) => handleChange(e, 'pincode')} /> : user.pincode}</td>
                                            <td>
                                                {editingRow === index ? (
                                                    <>
                                                    <button className='btn btn-primary btn-sm' onClick={() => handleSave(user.id)}>Save</button>

                                                    <button className='btn btn-primary btn-sm' onClick={() => handleCancel()}>Cancel</button>
                                                    </>
                                                ) : (
                                                    <button className='btn btn-success btn-sm' onClick={() => handleEdit(index, user)}>Edit</button>
                                                )}
                                                <button className='btn btn-danger btn-sm' onClick={() => handleDelete(user.id)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" className="text-center">No Data Available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserCatalogue;
