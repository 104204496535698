import { React, useEffect, useState } from 'react'
import img_1 from '../Photos/image_1.jpg'
import img_2 from '../Photos/image_2.jpg'
import img_3 from '../Photos/image_3.jpg'
import './hero.css'
import CategoryData from '../Category/CategoryData'
import Product from '../MainProduct/Product'
import { ProductList } from '../ProductCart/ProductList'
import NewSlider from '../Testimonials/NewSlider'
import MoreInfo from './MoreInfo'
import Benefits from './Benefits'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import fb from '../Photos/fb.svg'
import insta from '../Photos/insta.svg'
import twit from '../Photos/twitter.svg'
import YouTube from '../YouTubeVideo/YouTube'
import Reviews from '../AdminReview/Reviews'
import Counter from '../Counter/Counter'
import Statistics from '../Satistics/Statistics'

const HeroSection = ({ user_id, role }) => {

    const navigate = useNavigate();

    const [images, setImages] = useState([]);

    const handleedithero = () => {
        navigate('/edit_herosection')
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_herosection`);
                if (res.status === 200) {
                    setImages(res.data);
                    // console.log("Hero Ka Data : ", res.data)
                }
            } catch (error) {
                // console.error("Error fetching product data:", error.message);
            }
        };

        fetchData();
    }, []);


    const handledeleteimg = async (e, id) => {
        // console.log("Delete ID:", id)
        try {
            // // console.log();
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/delete_heroimage/${id}`,
            );
            // console.log(response.data);
            if (response.data.success = true) {
                alert("Deleted Successfully")
                window.location.reload();
            }
        } catch (error) {
            // console.error("Error:", error);
        }
    }
    return (
        <>
            {role == 1 ? (
                <>
               {/* <Statistics/> */}
               
                <div className="top_hero_buttons mb-2">
                    <div className='w-50'>
                    <button className='btn btn-success col-md-4' onClick={handleedithero}>Add Images</button>
                    </div>
                    <div className='d-flex flex-row w-50 gap-5'>
                    {images.map((data) => (
                        <button key={data.id} className='btn btn-danger col-md-4' onClick={(e, data_id) => handledeleteimg(e, data.id)}>Delete</button>
                    ))}
                    </div>
                </div>
</>
            ) : (<>
            </>)}

            <div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {images.map((_, i) => (
                        <button
                            key={i}
                            type="button"
                            data-bs-target="#myCarousel"
                            data-bs-slide-to={i.toString()}
                            className={i === 0 ? "active" : ""}
                            aria-label={`Slide ${i + 1}`}
                            aria-current={i === 0 ? "true" : "false"}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {images.map((data, i) => (
                        <>
                            <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`}>
                                <img
                                    className="bd-placeholder-img"
                                    width="100%"
                                    height="100%"
                                    src={`${process.env.REACT_APP_BASE_URL}/view_doc/${data.img}`}
                                    alt={`Slide ${i + 1}`}
                                    aria-hidden="true"
                                    preserveAspectRatio="xMidYMid slice"
                                    focusable="false"
                                />

                            </div>

                            {/* <div className="hero-buttons-container d-flex justify-content-cdnter mb-3">
                                {role == 1 && (
                                    <button className='btn btn-success' onClick={handleedithero}>Add Images</button>
                                )}
                                {role == 1 && (
                                    <button className='btn btn-danger' onClick={(e) => handledeleteimg(e, data.id)}>Delete</button>
                                )}
                            </div> */}

                        </>

                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

            </div>



            <CategoryData role={role} />
            <ProductList user_id={user_id} />
            {/* <NewSlider/> */}
            <br />
            <MoreInfo />
            <YouTube/>

            <Reviews/>
            {role==1 ?( <Counter/>):(<></>)}
           
            {/* <Benefits/> */}

            <div className="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <div class="col-md-4 d-flex align-items-center">
                        <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
                            <svg class="bi" width="30" height="24"></svg>
                        </a>
                        <span class="mb-3 mb-md-0 text-body-secondary">© Organicko</span>
                    </div>

                    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                        <li class="ms-3"><a class="text-body-secondary" href="https://www.facebook.com/Organick0"><img class="bi" width="24" height="24" src={fb}></img></a></li>
                        <li class="ms-3"><a class="text-body-secondary" href="https://www.instagram.com/organick0/"><img class="bi" width="24" height="24" src={insta}></img></a></li>
                        <li class="ms-3"><a class="text-body-secondary" href="#"><img class="bi" width="24" height="24" src={twit}></img></a></li>
                    </ul>
                </footer>
            </div>


        </>
    )
}

export default HeroSection
