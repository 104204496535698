import React, { useEffect, useState } from 'react';
import './statistics.css';
import axios from 'axios';
import { Pie, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale);

const Statistics = () => {
    const [stats, setStats] = useState({
        total_cost_price: 0,
        total_selling_price: 0,
        profit: 0
    });

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
            hoverBackgroundColor: ['#FF4D6D', '#2D91D1', '#E5B900', '#3EA7A7', '#844DFF', '#E57200']
        }]
    });

    const [lineChartData, setLineChartData] = useState({
        labels: [],
        datasets: []
    });

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);

    // Generate the last 5 years dynamically
    const years = Array.from({ length: 6 }, (_, i) => currentYear - i);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_stats`);
                const sales = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_product_sales?year=${selectedYear}`);
                const line_chart = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_line_chart_data?year=${selectedYear}`);

                if (res.status === 200) {
                    setStats(res.data);
                }

                if (sales.status === 200) {
                    const data = sales.data;
                    const labels = data.map(item => item.product_name);
                    const counts = data.map(item => item.products_count);

                    setChartData({
                        labels,
                        datasets: [{
                            data: counts,
                            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                            hoverBackgroundColor: ['#FF4D6D', '#2D91D1', '#E5B900', '#3EA7A7', '#844DFF', '#E57200']
                        }]
                    });
                }

                if (line_chart.status === 200) {
                    const data = line_chart.data;

                    // Extract unique product names
                    const productNames = [...new Set(data.map(item => item.product_name))];

                    // Generate labels for months (1-12)
                  // Generate labels for months (1-12)
                  // Month names array
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

// Use month names instead of "Month 1, Month 2..."
const labels = monthNames;



                    // Create dataset for each product
                    const datasets = productNames.map(product => {
                        return {
                            label: product,
                            data: labels.map((_, i) => {
                                const salesData = data.find(item => item.product_name === product && item.month === i + 1);
                                return salesData ? salesData.products_count : 0;
                            }),
                            borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random Color
                            fill: false,
                        };
                    });

                    setLineChartData({ labels, datasets });
                }

            } catch (error) {
                console.error("Error fetching statistics data:", error.message);
            }
        };

        fetchData();
    }, [selectedYear]); // Fetch data when selectedYear changes

    return (
        <div className="container">
            <div className="stats_head">
                <h3>Statistics</h3>
                <div className='outer_stats_card'>
                    <div className='stats_card'>
                        <h4>Total MRP:</h4>
                        <p>Rs. {stats.total_cost_price}/-</p>
                    </div> 

                    <div className='stats_card'>
                        <h4>Total Selling:</h4>
                        <p>Rs. {stats.total_selling_price}/-</p>
                    </div> 

                    <div className='stats_card'>
                        <h4>Net Profit:</h4>
                        <p 
                            style={{ 
                                color: stats.profit < 0 ? 'red' : 'green', 
                                fontWeight: 'bold' 
                            }}
                        >
                            Rs. {stats.profit}/-
                        </p>
                    </div> 
                </div>

                {/* Pie Chart Container with Year Filter Dropdown */}
<hr/>
                <div className='outer_main_charts'>
                    <div className='year_dropdown'>
                    <div className="text-center">
                    <h3>Charts Data</h3>
                    </div>
                    <select 
                        className="form-select"
                        value={selectedYear} 
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        {years.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                    </div>
                    <div className='main_charts_cont'>
                    <div className="chart-container">
    <h5>Product Sales Distribution</h5>
    {chartData.datasets[0].data.length > 0 && chartData.datasets[0].data.some(value => value > 0) ? (
        <Pie data={chartData} className='pie-bg' />
    ) : (
        <p className="no-data-message">No data available</p>
    )}
</div>

{/* Line Chart for Product Sales Trends */}
<div className="chart-container">
    <h5>Product Sales Trends (Monthly)</h5>
    {lineChartData.datasets.length > 0 && lineChartData.datasets.some(dataset => dataset.data.some(value => value > 0)) ? (
        <Line data={lineChartData} className='pie-bg' />
    ) : (
        <p className="no-data-message">No data available</p>
    )}
</div>

                </div>
                </div>

            </div>
        </div>
    );
}

export default Statistics;
