import React, { useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useEffect, useState } from 'react'
import axios from 'axios';
import '../PlaceOrders/placedorder.css'
import { useNavigate } from 'react-router-dom';
const ViewBill = () => {

    const tableRef = useRef(null);

    const navigate=useNavigate();
    const [charges,setCharges]=useState(0)

    const [productList, setProductList] = useState([]);
    const [orderstatus, setOrderStatus] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [status_id, setStatusID] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/order_products`);
                if (res.status === 200) {
                    setProductList(res.data);
                    console.log("Confirm Orders : ", res.data)
                }
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        };

        const fetchOrderStatus = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_order_status`);
                if (res.status === 200) {
                    setOrderStatus(res.data);
                    console.log("Confirm Orders : ", res.data)
                }
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        };

        fetchData();
        fetchOrderStatus();
    }, []);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleUpdateStatus = async (order_id, product_id, uid, quantity) => {
        console.log("Data we get : ", order_id, product_id, uid, quantity, "Status id:", status_id);
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_status/${order_id}/${uid}/${product_id}/${status_id}/${quantity}`);
            if (res.status === 200) {
                alert("Updated Successfully");
                window.location.reload()
            } else {
                alert("Insufficient Stock!"); // Show error message in alert
            }
        } catch (error) {
            console.log("Error aahe bhava:", error);
            alert("Error updating status. Please try again later."); // Show error message in alert
        }
    }


  
    useEffect(() => {
        const filtered = productList.filter(product =>
            `${product.firstName} ${product.lastName}`.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredProducts(filtered);
    }, [searchValue, productList]);

    const handleSearch = event => {
        setSearchValue(event.target.value);
    };

   
    const handledownloadinvoice = (order_id,product_name,size,status,order_date,order_time,uid) => {

        console.log("Order ID:",order_id,product_name,size,status)
            navigate('/bill_by_admin', { state: {order_id:order_id,product_name:product_name,size:size,status_id:status,order_date:order_date,order_time:order_time,uid:uid} })
    };

    const handledcharges=(e)=>{
        // console.log("Charges: ",e.target.value)

        setCharges(e.target.value)
    }

    const handleaddcharges=async(order_id)=>{
       
        try {
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/update_delivery_charges/${order_id}/${charges}`);
            if (res.status === 200) {
                alert("Updated Successfully");
                window.location.reload()
            } else {
                alert("Something went wrong!!"); 
            }
        } catch (error) {
            // console.log("Error aahe bhava:", error);
            alert("Error updating status. Please try again later."); // Show error message in alert
        }
       


        
    }

    return (


        <div className="container">
            <div className="placed_order_container mt-5">
            <div className="search-bar w-100">
                    <div>
                        <input type="text" value={searchValue} onChange={handleSearch} placeholder="Search by customer name" />
                    </div>


                    <DownloadTableExcel
                        filename="order_bill table"
                        sheet="users"
                        currentTableRef={tableRef.current}
                    >
                        <div className='d-flex justify-content-center align-items-center'>
                            <button className='btn btn-danger'> Export excel </button>
                        </div>


                    </DownloadTableExcel>
                </div>

                <div className="category_head mt-5">
                    <h3>View Bills</h3>
                </div>
                {/* <div className="table-container">
                    <div className="inventory_table">
                        <div className="table">
                            <table className="table table-bordered" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th scope="col">O_ID</th>
                                        <th scope="col">Product</th>
                                        <th scope="col">P_ID</th>
                                        <th scope="col">Quant.</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Order_Date</th>
                                        <th scope="col">Customer</th>
                                        <th scope="col">Contact</th>
                                        <th scope="col">View Bill</th>
                                        <th scope="col">Add Delivery Charges</th>
                                    </tr>
                                </thead>
                                <tbody>
    {filteredProducts.length > 0 ? (
        filteredProducts.map((data, i) => (
            <tr className={`${data.paid_name === 'Paid' ? 'table-success' : 'table'}`} key={i}>
                <td>OR_{data.order_id}</td>
                <td>
                    {data.product_name}
                </td>
                <td>PR_{data.product_id}</td>
                <td>{data.quantity}</td>
                <td>₹{data.total_price}/-</td>
                <td style={{ fontSize: '14px' }}>{formatDate(data.order_date)}</td>
                <td style={{ fontSize: '14px' }}>Mr.{data.firstName} {data.lastName}</td>
                <td style={{ fontSize: '14px' }}>{data.phoneNumber}</td>
                <td>
                    <button className='btn btn-success btn-sm d-flex justify-center align-center' onClick={() => handledownloadinvoice(data.order_id, data.product_name, data.product_size, data.status, data.order_date, data.order_time, data.uid)}>View</button>
                </td>
                <td>
                    {data.d_charges > 0 ? (
                        <div className='d-flex gap-2 align-items-center justify-content-center'>
                            <input type="number" className="form-control" id="exampleInputCharge" onChange={(e) => handledcharges(e)} placeholder={data.d_charges} />
                            <button className='btn btn-secondary mt-0' onClick={() => handleaddcharges(data.order_id)}>Update</button>
                        </div>
                    ) : (
                        <div className='d-flex gap-2 align-items-center justify-content-center'>
                            <input type="number" className="form-control" id="exampleInputCharge" onChange={(e) => handledcharges(e)} />
                            <button className='btn btn-danger mt-0' onClick={() => handleaddcharges(data.order_id)}>Add</button>
                        </div>
                    )}
                </td>
            </tr>
        ))
    ) : (
        <tr>
            <td colSpan="10" className="text-center text-muted">No orders available</td>
        </tr>
    )}
</tbody>

                            </table>
                        </div>
                    </div>
                </div> */}

<div className="table-container">
    <div className="inventory_table">
        <div className="table">
            <table className="table table-bordered" ref={tableRef}>
                <thead>
                    <tr>
                        <th scope="col">O_ID</th>
                        <th scope="col">Product</th>
                        <th scope="col">P_ID</th>
                        <th scope="col">Quant.</th>
                        <th scope="col">Price</th>
                        <th scope="col">Order_Date</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Contact</th>
                        <th scope="col">View Bill</th>
                        <th scope="col">Add Delivery Charges</th>
                    </tr>
                </thead>
                <tbody>
    {filteredProducts.length > 0 ? (
        (() => {
            let orderGroupCounts = {}; // Store row span count for each (order_time, phoneNumber) pair
            let rowCounter = {}; // Track the row index for each group

            // Count occurrences of each (order_time, phoneNumber)
            filteredProducts.forEach((data) => {
                const key = `${data.order_time}-${data.phoneNumber}`;
                orderGroupCounts[key] = (orderGroupCounts[key] || 0) + 1;
            });

            let renderedGroups = new Set(); // Track already rendered groups

            return filteredProducts.map((data, i) => {
                const key = `${data.order_time}-${data.phoneNumber}`;
                let rowSpanValue = orderGroupCounts[key];

                // Track row count per group
                rowCounter[key] = (rowCounter[key] || 0) + 1;
                let currentRowIndex = rowCounter[key];
                let isFirstOccurrence = !renderedGroups.has(key);
                let isMiddleRow = currentRowIndex === Math.ceil(rowSpanValue / 2); // Middle row logic

                if (isFirstOccurrence) {
                    renderedGroups.add(key); // Mark as rendered
                }

                return (
                    <tr key={i} 
                        className={data.paid_name === 'Paid' ? 'table-success' : 'table'}
                        style={currentRowIndex === rowSpanValue ? { borderBottom: "1px solid black" } : {}}
                    >
                        {/* Show O_ID only for the first occurrence */}
                        {isFirstOccurrence ? (
                            <td rowSpan={rowSpanValue}>OR_{data.order_id}</td>
                        ) : null}

                        <td>{data.product_name}</td>
                        <td>PR_{data.product_id}</td>
                        <td>{data.quantity}</td>
                        <td>₹{data.total_price}/-</td>
                        <td style={{ fontSize: '14px' }}>{formatDate(data.order_date)}</td>
                        <td style={{ fontSize: '14px' }}>Mr.{data.firstName} {data.lastName}</td>
                        <td style={{ fontSize: '14px' }}>{data.phoneNumber}</td>

                        {/* Show "View Bill" button only for the first row in the group */}
                        {isFirstOccurrence && (
                            <td rowSpan={rowSpanValue}>
                                <button className='btn btn-success btn-sm d-flex justify-center align-center' 
                                    onClick={() => handledownloadinvoice(data.order_id, data.product_name, data.product_size, data.status, data.order_date, data.order_time, data.uid)}>
                                    View
                                </button>
                            </td>
                        )}

                        {/* Show "Add Delivery Charges" input at the middle row of the group */}
                        {isMiddleRow && (
                            <td>
                                {data.d_charges > 0 ? (
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => handledcharges(e)}
                                            placeholder={data.d_charges}
                                        />
                                        <button className="btn btn-secondary" onClick={() => handleaddcharges(data.order_id)}>
                                            Update
                                        </button>
                                    </div>
                                ) : (
                                    <div className="d-flex gap-2 align-items-center justify-content-center">
                                        <input
                                            type="number"
                                            className="form-control"
                                            onChange={(e) => handledcharges(e)}
                                        />
                                        <button className="btn btn-danger" onClick={() => handleaddcharges(data.order_id)}>
                                            Add
                                        </button>
                                    </div>
                                )}
                            </td>
                        )}
                    </tr>
                );
            });
        })()
    ) : (
        <tr>
            <td colSpan="10" className="text-center text-muted">No orders available</td>
        </tr>
    )}
</tbody>

            </table>
        </div>
    </div>
</div>





            </div>
        </div>
    )
}

export default ViewBill
