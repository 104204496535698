import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'sonner';
import {useNavigate} from 'react-router-dom'


const PhoneEntry = () => {
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userdata, setUserData] = useState([]);
    const [productinfo, setProductInfo] = useState([]);
    const [orders, setOrders] = useState([]); // State to store the list of orders
    const navigate=useNavigate();
    const [mobilenumber,setMobileNumber]=useState('');
    const [formData, setFormData] = useState({
        mobilenumber: 0,
        category: '',
        size: '',
        price: '',
        quantity: '',
        discount: '',
        total_price: '',
        d_charge: ''
    });

    const [totaloverallprice, setTotalOverallPrice] = useState({
        total_price: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_category_data`);
                if (res.status === 200) {
                    setCategory(res.data);
                }
            } catch (error) {
                console.error("Error fetching category data:", error.message);
            }
        };

        fetchData();
    }, []);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if(name==='mobilenumber'){
            setMobileNumber(value);
        }

        if (name === 'category') {
            const id = formData.category;
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproductslist/${id}`);
                setProductInfo(res.data);
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        } else if (name === 'product') {
            const selectedProduct = productinfo.find(product => product.product_id == value);
            if (selectedProduct) {
                setFormData(prevState => ({
                    ...prevState,
                    size: selectedProduct.product_size,
                    price: selectedProduct.product_price,
                    product: selectedProduct.product_id,
                }));
            }
        } else if (name === 'quantity' || name === 'discount' || name === 'd_charge') {
            const quantity = parseFloat(value);
            const discount = parseFloat(formData.discount);
            const price = parseFloat(formData.price);
            console.log("Product Price: ", price)
            const delivery = parseFloat(formData.d_charge);

            let totalPrice = quantity * price * (1 - discount / 100) + delivery;
            let total_Price = quantity * price * (1 - discount / 100);

            setTotalOverallPrice(prevState => ({
                ...prevState,
                total_price: totalPrice.toFixed(2),
            }))

            setFormData(prevState => ({
                ...prevState,
                total_price: total_Price.toFixed(2),
            }));
        }
    };

    useEffect(() => {
        const fetchProductInfo = async () => {
            try {
                const id = formData.category;
                console.log("ID:", id)
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/getproductslist/${id}`);
                setProductInfo(res.data);
            } catch (error) {
                console.error("Error fetching product data:", error.message);
            }
        };

        if (formData.category !== '') {
            fetchProductInfo();
        }
    }, [formData.category]);

    const handleCategory = async (e) => {
        const { name, value } = e.target;
        console.log(name)
        setFormData({ ...formData, [name]: value });
        console.log("Categrory:", formData.category);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newOrder = {
            product_name: productinfo.find(product => product.product_id == formData.product)?.product_name,
            category: category.find(cat => cat.ID == formData.category)?.name,
            quantity: formData.quantity,
            price: formData.price,
            discount: formData.discount,
            delivery_charges: formData.d_charge,
            total_price: totaloverallprice.total_price,
            product_id:productinfo.find(product => product.product_id == formData.product)?.product_id,
        };

        setOrders([...orders, newOrder]);

        // Reset form data
        setFormData({
            mobilenumber: formData.mobilenumber,
            category: '',
            size: '',
            price: '',
            quantity: '',
            discount: '',
            total_price: '',
            d_charge: ''
        });

        setTotalOverallPrice({
            total_price: 0
        });
    };

    const handleFinalSubmit = async () => {
        console.log(orders);
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/confirm_order_admin/${mobilenumber}`, 
                { orders }
            );
    
            if (response.data.success === false) {
                if (response.data.message === "Insufficient stock") {
                    toast.warning("Some products have insufficient stock. Please update the quantity.");
                } else {
                    toast.warning(response.data.message || "Order could not be placed.");
                }
            } else if (response.data.success === true) {
                toast.success("Order confirmed successfully!");
                setTimeout(() => {
                    navigate('/placed_orders');
                }, 3000); 
            }
        } catch (error) {
            console.error("Error:", error);
    
            // Check if the error response exists and if the status code is 400
            if (error.response && error.response.status === 400) {
                toast.warning("Some products have insufficient stock. Please update the quantity.");
            } else {
                toast.error("Something went wrong. Please try again.");
            }
        }  finally {
            setLoading(false);
        }
    };
    

    const handlegetuserdata = async (e) => {
        e.preventDefault();
        if (formData.mobilenumber == 0) {
            alert("Please Enter the mobile number!!");
        } else {
            try {
                const phone = formData.mobilenumber;
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getUserInfoByPhone/${phone}`);

                if (response.status === 200) {
                    setUserData(response.data.user_data);
                }

            } catch (error) {
                alert("No user found!!");
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeleteOrder = (index) => {
        const updatedOrders = orders.filter((_, i) => i !== index);
        setOrders(updatedOrders);
    };

    return (
        <>
            {loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            <div className="add_product mb-2" style={{ marginTop: '5%' }}>
                <Toaster position="top-center" richColors />

                <div className="container">
                    <div className="add_product_container">
                        <div className="register_head mb-3">
                            <h2>Add User Orders</h2>
                            <p>Please provide the user Contact number...</p>
                        </div>

                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-md-12">
                                <label htmlFor="mobilenumber" className="form-label">Mobile Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="mobilenumber"
                                    name="mobilenumber"
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-12">
                                <button className='btn btn-danger col-md-12' onClick={(e) => handlegetuserdata(e)}>Get User Data</button>
                            </div>

                            {userdata.length > 0 && (
                                userdata.map((data, i) => (
                                    <div key={i} className='d-flex justify-content-center align-items-center'>
                                        <div className="upload-square text-center" style={{ width: '50rem' }}>
                                            <h7> <b>Name: </b>{data.firstName} {data.lastName}</h7>
                                            <br />
                                            <h7><b>Address:</b> {data.address1}, {data.address2} {data.city} {data.state} {data.pincode}</h7>
                                        </div>
                                    </div>
                                ))
                            )}

                            <div className="col-md-4">
                                <label htmlFor="category" className="form-label">Category</label>
                                <select className="form-select" aria-label="Default select example" id="category" name="category" onChange={handleChange}>
                                    <option defaultChecked={true}>--Select Category--</option>
                                    {category.map((data, i) => (
                                        <option value={data.ID} key={i}>{data.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="size" className="form-label">Product</label>
                                <select className="form-select" aria-label="Default select example" id="product" name="product" onChange={handleChange}>
                                    <option defaultChecked>--Select Product--</option>
                                    {productinfo.map((data, index) => (
                                        <option value={data.product_id} key={index}>{data.product_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="size" className="form-label">Size</label>
                                <select className="form-select" aria-label="Default select example" id="size" name="size" value={formData.size} onChange={handleChange}>
                                    <option defaultChecked>--Select Size--</option>
                                    {productinfo.map((product, index) => (
                                        product.product_id === formData.product && (
                                            <option value={product.product_size} key={index}>{product.product_size}</option>
                                        )
                                    ))}
                                </select>
                            </div>


                            <div className="col-md-4">
                                <label htmlFor="prices" className="form-label">Price</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="prices"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="discount" className="form-label">Delivery Charges</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="d_charge"
                                    name="d_charge"
                                    value={formData.d_charge}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="discount" className="form-label">Discount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="discount"
                                    name="discount"
                                    value={formData.discount}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="quantity" className="form-label">Quantity</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="quantity"
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                />
                            </div>


                            <div className="col-md-6">
                                <label htmlFor="total_price" className="form-label">Total Price</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="total_price"
                                    name="total_price"
                                    value={totaloverallprice.total_price}
                                    readOnly
                                />
                            </div>

                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <button type="submit" className="btn btn-success col-12" style={{ backgroundColor: '#095809' }}>Add Order</button>
                            </div>
                        </form>

                        {orders.length > 0 && (
                            <div className="mt-4">
                                <h3>Orders</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Category</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Discount</th>
                                            <th>Delivery Charges</th>
                                            <th>Total Price</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order, index) => (
                                            <tr key={index}>
                                                <td>{order.product_name}</td>
                                                <td>{order.category}</td>
                                                <td>{order.quantity}</td>
                                                <td>{order.price}</td>
                                                <td>{order.discount}</td>
                                                <td>{order.delivery_charges}</td>
                                                <td>{order.total_price}</td>
                                                <td>
                                                <button 
                                            className="btn btn-sm btn-danger"
                                            onClick={() => handleDeleteOrder(index)}
                                        >Delete</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {orders.length > 0 && (
                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <button type="button" className="btn btn-primary col-12" onClick={handleFinalSubmit}>Final Submit</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhoneEntry;