


import { React, useState, useEffect } from 'react'
import '../Profile/Bill.css'
import logo from '../Photos/logo.png'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
const AdminBill = () => {

    const [user_data, setUserData] = useState([])
    const [totalPrices, setTotalPrices] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [uniqueuser, setUniqueUserData] = useState([])

    const location = useLocation();
    const order_id = location.state?.order_id || [];
    const size = location.state?.size || [];
    const uid = location.state?.uid || [];
    const status_id = location.state?.status_id || 5;
    const order_date = location.state?.order_date || [];
    const order_time = location.state?.order_time || [];
    const product_name = location.state?.product_name || [];
    // // // console.log("User Bill Data : ", order_id, "Status:", status_id)



    // const formatDate = (dateString) => {
    //     const inputDate = new Date(dateString);
    //     const day = inputDate.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    //     const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Get month (zero-based index) and pad with leading zero if needed
    //     const year = inputDate.getFullYear().toString(); // Get full year

    //     return `${day}-${month}-${year}`;
    // };


    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDownloadPDF = () => {
        const input = document.getElementById('print-area');
        const pdfWidth = screenWidth > 768 ? input.offsetWidth : input.offsetWidth / 2;
        const pdfHeight = input.offsetHeight;

        html2canvas(input, { width: pdfWidth, height: pdfHeight }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('invoice.pdf');
        });
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/get_admin_bills/${order_date}/${order_time}/${uid}`);
                if (res.data.length > 0) {
                    // setUserData(res.data);
                    // // console.log("User Data :", res.data);
                    setUserData(res.data)
                    const uniqueUserData = [];
                    const userIds = new Set();
                    res.data.forEach((item) => {
                        if (!userIds.has(item.id)) {
                            uniqueUserData.push(item);
                            userIds.add(item.id);
                        }
                    });
                    setUniqueUserData(uniqueUserData);

                    const totalPrices = res.data.map((item) => item.total_price);
                    const subtotal = totalPrices.reduce((acc, curr) => acc + parseFloat(curr), 0);
                    setTotalPrices(subtotal);
                } else {
                    // // console.log("No user data found.");
                }
            } catch (error) {
                // // console.log("Error fetching user data:", error);
            }
        };

        fetchData();
    }, [order_id]);

    const deliveryCharges = user_data.some(item => item.d_charges !== 0) ? user_data.find(item => item.d_charges !== 0).d_charges : (parseFloat(totalPrices) < 500 ? 100 : 0);



    const handlePrintPDF = () => {
        window.print();
    }

    let totalPriceWithExtra = 0;

    const getStatusName = (status) => {
        switch (status) {
            case 0:
                return 'Order Placed';
            case 1:
                return 'Dispatched';
            case 2:
                return 'Delivered';
            default:
                return 'Unknown Status';
        }
    };

    return (
        <div>
            <div class="invoice-wrapper" id="print-area">
                <div class="invoice">
                    <div class="invoice-container">


                        <div class="invoice-head">
                            <div class="invoice-head-top">
                                <div class="invoice-head-top-left text-start">
                                    <img src={logo} />
                                </div>
                                <div class="invoice-head-top-right text-end">
                                    <h3>Invoice</h3>
                                </div>
                            </div>
                            <div class="hr"></div>
                            <div class="invoice-head-middle">
                                <div class="invoice-head-middle-left text-start">
                                    {/* <p><span class="text-bold">Date</span>: {formatDate(data.order_date)}</p> */}
                                </div>

                                <div class="invoice-head-middle-right text-end">
                                    {/* <p><span class="text-bold">Invoice No:</span> {data.order_id}</p> */}
                                </div>
                            </div>
                            <div class="hr"></div>
                            <div class="invoice-head-bottom">
                                {uniqueuser.map((data, i) => (
                                    <div class="invoice-head-bottom-left">
                                        <ul>
                                            <li class='text-bold'>Invoice Number: 000{data.order_id}</li>
                                            <li class='text-bold'>Invoiced To:</li>
                                            <li>{data.firstName} {data.lastName}</li>
                                            <li>{data.address1},<br />
                                                {data.street_add}</li> <li>{data.landmark}</li>

                                            <li>{data.city}, {data.state} {data.country}</li>
                                        </ul>
                                    </div>
                                ))}

                                <div class="invoice-head-bottom-right">
                                    <ul class="text-end">

                                        <li class='text-bold'>Pay To:</li>
                                        <li> Organicko Enterprises</li>
                                        {/* <li>Order ID: {data.order_id}</li> */}
                                        <li>Mulund, Mumbai</li>
                                        <li>organoenterprises@gmail.com</li>
                                        <br />
                                        {/* <p className='text-end'><b>Status: </b>{getStatusName(status_id)}</p> */}
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div class="overflow-view">

                            <div class="invoice-body">
                                <table>
                                    <thead>
                                        <tr>
                                            <td class="text-bold">Sr no.</td>
                                            {/* <td class="text-bold">Order ID</td> */}
                                            <td class="text-bold">Product</td>
                                            <td class="text-bold">Size</td>
                                            <td class="text-bold">QTY</td>
                                            <td class="text-bold">Amount</td>
                                            {/* <td class="text-bold">Status</td> */}
                                        </tr>
                                    </thead>
                                    {user_data.map((data, i) => (
                                        <tbody>

                                            <tr>
                                                <td>{i + 1}</td>
                                                {/* <td>{data.order_id}</td> */}
                                                <td>{data.product_name}</td>
                                                <td>{data.product_size}</td>
                                                <td>{data.quantity}</td>
                                                <td>₹ {data.total_price}</td>
                                                {/* <td class="text-end">{data.name}</td> */}
                                            </tr>
                                        </tbody>
                                    ))}

                                </table>

                                <div class="invoice-body-info-item border-bottom">
                                    <div class="info-item-td text-end text-bold">Sub Total:</div>
                                    <div class="info-item-td text-center">₹ {(parseFloat(totalPrices)).toFixed(2)}/-</div>
                                </div>

                                <div class="invoice-body-bottom">

                                    {/* {parseFloat(totalPrices) > 500 ? (
                                        <>
                                            <div class="invoice-body-info-item border-bottom">
                                                <div class="info-item-td text-end text-bold">Delivery Charges: </div>
                                                <div class="info-item-td text-center">₹ {0}</div>
                                            </div>
                                            <div class="invoice-body-info-item">
                                                <div class="info-item-td text-end text-bold">Total:</div>
                                                <div className="info-item-td text-center">₹ {parseFloat(totalPrices)}/-</div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div class="invoice-body-info-item border-bottom">
                                                <div class="info-item-td text-end text-bold">Delivery Charges: </div>
                                                <div class="info-item-td text-center">₹ {100}.00</div>
                                            </div>
                                            <div class="invoice-body-info-item">
                                                <div class="info-item-td text-end text-bold">Total:</div>
                                                <div className="info-item-td text-center">₹ {parseFloat(totalPrices) + 100}/-</div>
                                            </div>
                                        </>
                                    )} */}

                                    <div class="invoice-body-info-item border-bottom">
                                        <div class="info-item-td text-end text-bold">Delivery Charges: </div>
                                        <div class="info-item-td text-center">₹ {deliveryCharges}</div>
                                    </div>
                                    <div class="invoice-body-info-item">
    <div class="info-item-td text-end text-bold">Total:</div>
    <div className="info-item-td text-center">₹ {(parseFloat(totalPrices) + deliveryCharges).toFixed(2)}/-</div>
</div>


                                </div>
                            </div>


                        </div>
                        <div class="invoice-foot text-center">
                            <p><span class="text-bold text-center">NOTE:&nbsp;</span>This is computer generated receipt and does not require physical signature.</p>

                            <div class="invoice-btns">

                                <button className='btn btn-success invoice-btn' onClick={handlePrintPDF}>Download</button>
                                {/* <button className='btn btn-danger invoice-btn' onClick={handleDownloadPDF}>Download</button> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminBill




